import { tokenManager } from "./auth-client";

export class APIClient {
  private baseURL: string;

  constructor(config?: { baseURL?: string }) {
    this.baseURL = config?.baseURL || process.env.NEXT_PUBLIC_API_URL || "/";
  }

  private async getAuthToken(): Promise<string | null> {
    // Handle token retrieval for client-side
    if (typeof window !== "undefined") {
      return await tokenManager.getToken();
    }

    // Handle token retrieval for server-side
    const { cookies } = require("next/headers");
    return cookies().get("auth_token")?.value ?? null;
  }

  async fetch<T>(endpoint: string, options: RequestInit = {}): Promise<T> {
    try {
      const token = await this.getAuthToken();

      if (!token) {
        throw new Error("Unauthorized");
      }

      const response = await fetch(`${this.baseURL}${endpoint}`, {
        ...options,
        headers: {
          "Content-Type": "application/json",
          ...options.headers,
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 401) {
        // Clear invalid token
        tokenManager.clearToken();
        throw new Error("Unauthorized");
      }

      if (!response.ok) {
        throw new Error(`API error: ${response.statusText}`);
      }

      return response.json();
    } catch (error) {
      if (error instanceof Error && error.message === "Unauthorized") {
        // Handle authentication errors (redirect to login, etc)
        tokenManager.clearToken();
      }
      throw error;
    }
  }
}
