"use client";

import { LicenseManager } from "@ag-grid-enterprise/core";
import "@ag-grid-community/styles/ag-grid.css"; // Core CSS
import "@ag-grid-community/styles/ag-theme-quartz.css"; // Theme

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-045043 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Caltana, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Caltana )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Caltana )_need_to_be_licensed___( Caltana )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 20 September 2024 )____[v2]_MTcyNjc4NjgwMDAwMA==5c52d208817006ae5e65ac5937e5758b",
);

export default function AgGridInit() {
  return null;
}
