interface TokenResponse {
  token: string;
  expiresIn: number;
}

class TokenManager {
  private token: string | null = null;
  private expiresAt: number = 0;

  async getToken(): Promise<string> {
    // Check if token is still valid (with some buffer time)
    if (this.token && Date.now() < this.expiresAt - 30000) {
      return this.token;
    }

    // Fetch new token
    const response = await fetch("/api/auth/token");
    if (!response.ok) {
      console.error("Failed to refresh token", response.statusText);
      throw new Error("Failed to refresh token");
    }

    const data: TokenResponse = await response.json();

    this.token = data.token;
    this.expiresAt = Date.now() + data.expiresIn * 1000;

    return this.token;
  }

  clearToken() {
    this.token = null;
    this.expiresAt = 0;
  }
}

export const tokenManager = new TokenManager();
