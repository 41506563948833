"use client";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { UserProfile } from "src/api/types";
import { profileQueryFns, profileQueryKeys } from "src/api/usersServer";

function getEnv() {
  const currentBranch = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
    return "prod";
  }

  return currentBranch;
}
function isDatadogEnabled() {
  return (
    process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
  );
}

// Datadog RUM configuration definition: https://docs.datadoghq.com/real_user_monitoring/browser/
// Disable datadog for local development environments
if (isDatadogEnabled()) {
  datadogLogs.init({
    clientToken: "pub991a3cacb1eba9538678833aec0ac23b",
    site: "datadoghq.com",
    service: "web-next",
    env: getEnv(),
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    forwardConsoleLogs: ["error", "warn"],
    forwardErrorsToLogs: true,
    forwardReports: "all",
    sessionSampleRate: 100,
    storeContextsAcrossPages: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
  });
  datadogRum.init({
    applicationId: "c9568ff5-acac-4874-83da-b7cd4248f23b",
    clientToken: "pub991a3cacb1eba9538678833aec0ac23b",
    site: "datadoghq.com",
    service: "web-next",
    env: getEnv(),
    allowedTracingUrls: [
      { match: "https://app-api.caltana.com", propagatorTypes: ["tracecontext", "datadog"] },
      { match: "https://dev-api.caltana.com", propagatorTypes: ["tracecontext", "datadog"] },
    ],
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    traceSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  const { data: user } = useQuery<UserProfile>({
    queryKey: profileQueryKeys.userProfile(),
    queryFn: () => profileQueryFns.userProfile(),
  });
  useEffect(() => {
    if (user?.email && isDatadogEnabled()) {
      datadogRum.setUser({
        email: user.email,
        name: user.name,
        id: user.id.toString(),
        organization: user.organization?.name,
      });
      datadogLogs.setUser({
        email: user.email,
        name: user.name,
        id: user.id.toString(),
        organization: user.organization?.name,
      });
    }
  }, [user]);
  return null;
}
